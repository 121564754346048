@font-face {
  font-family: 'BI Sans NEXT';
  src:
    local('BI Sans NEXT'),
    url('./fonts/bi-sans-next/BISansNEXT-Regular.otf') format('OpenType');
}

body,
html,
* {
  font-family: BI Sans NEXT !important;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

/* Allows Tiles component with no radio controls */
.no-radio-controls [class*='awsui_radio-control'] {
  display: none;
}